import { Injectable } from '@angular/core';
import { arrayOf, normalize, NormalizeOutput } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { OpenShiftSchema, ScheduleSchema } from '../../shared/schemas';
import { ShiftModel } from '../shift/shift.model';
import { Notify, ScheduleCopyRequest, ScheduleModel, ScheduleScope, SchedulesLoadRequest } from './schedule.model';

@Injectable()
export class RosterApi {
  private endpoint = 'rosters/';

  public constructor(private gateway: ApiGateway) {}

  public load(requestData: SchedulesLoadRequest, dispatchStart?: Action): Observable<NormalizeOutput> {
    const searchParams = {
      ...requestData,
      include: 'Exchange',
      optimized: true,
    };
    const search = objectToSearchParams(searchParams, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('Roster', res)),
        map((data) => normalize(data, arrayOf(ScheduleSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(schedule, period, dispatchStart?: Action): Observable<NormalizeOutput> {
    const search = objectToSearchParams(period);

    return this.gateway
      .post(this.endpoint, this.normalizeSchedulePayload(schedule), { params: search }, dispatchStart)
      .pipe(
        map((res) => reformatListResponse('Roster', res)),
        map((data) => normalize(data, arrayOf(ScheduleSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public update(
    occurrenceId,
    schedule,
    period,
    scope: ScheduleScope,
    dispatchStart?: Action,
  ): Observable<NormalizeOutput> {
    const search = objectToSearchParams(period);

    /**
     * It is possible to the user schedule.update method to change a schedule
     * to an Open Shift. This is the case when the user_id is set to null.
     * Therefore, a check is performed to see whether the response should be
     * handled as an array of Rosters or Open Shifts.
     */

    let responseType = 'Roster';

    return this.gateway
      .put(`${this.endpoint}${occurrenceId}/${scope}`, schedule, { params: search }, dispatchStart)
      .pipe(
        map((res) => {
          if (res.length > 0 && res[0].OpenShift) {
            responseType = 'OpenShift';
          }

          return reformatListResponse(responseType, res);
        }),
        map((data) => {
          const responseSchema = responseType === 'Roster' ? ScheduleSchema : OpenShiftSchema;
          return normalize(data, arrayOf(responseSchema), { assignEntity: assignSchemaEntity });
        }),
      );
  }

  public fetch(occurrenceId, dispatchStart?: Action): Observable<NormalizeOutput> {
    return this.gateway.get(this.endpoint + occurrenceId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Roster', res)),
      map((data) => normalize(data, ScheduleSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(
    occurrenceId,
    notify: Notify,
    scope: ScheduleScope,
    dispatchStart?: Action,
  ): Observable<ScheduleModel[]> {
    return this.gateway.delete(`${this.endpoint}${occurrenceId}/${scope}`, undefined, dispatchStart, notify);
  }

  public calculateBreak(breakData) {
    return this.gateway.post(this.endpoint + 'calculate_break', breakData);
  }

  public send(sendData) {
    return this.gateway.post(this.endpoint + 'send', sendData);
  }

  public checkTotal(shiftData): Observable<ShiftModel> {
    return this.gateway
      .post(this.endpoint + 'check/total', shiftData)
      .pipe(map((res) => reformatEntityResponse('Roster', res)));
  }

  public copy(payload: ScheduleCopyRequest) {
    return this.gateway.post(this.endpoint + 'copy', payload);
  }

  private normalizeSchedulePayload(schedule) {
    // break is mandatory in POST requests
    if (schedule.Roster && !schedule.Roster.break) {
      schedule.Roster.break = 0;
    }

    return schedule;
  }
}
